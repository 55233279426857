import { reqGoodsListClass } from '@/lib/request/auth2';

export const paramsTypeDef = {
    text: 'input',
    single: 'radio',
    multiple: 'select',
};

export const paramType = [
    { value: paramsTypeDef.text, label: '文本' },
    { value: paramsTypeDef.single, label: '单选' },
    { value: paramsTypeDef.multiple, label: '多选' },
];

//分类列表数据
export const getClassListData = () => {
    return new Promise(resolve => {
        reqGoodsListClass({
            pageNums: 1,
            pageSize: 100,
        })
            .then(res => {
                let list = res?.data?.list || [];
                list.forEach(info => {
                    info.value = info.id;
                    info.label = info.title;
                    info.children.forEach(subInfo => {
                        subInfo.value = subInfo.id;
                        subInfo.label = subInfo.title;
                    });
                });
                resolve(list);
            })
            .catch(res => {
                console.log('getClassListData error by', res);
                resolve([]);
            });
    });
};
