<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="classType">{{ getClassTypeName(row.classList) }}</template>
                <template slot-scope="{ row }" slot="type">{{ (paramType.find(info => info.value == row.type) || { label: row.type }).label }}</template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '否', 1: '是' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="ctime">{{ new Date(row.ctime * 1000).Format('yyyy-MM-dd HH:mm:ss') }}</template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqGoodsDelParameter, reqGoodsParameter } from '@/lib/request/auth2';
import { paramType } from './common';

export default {
    name: 'goodParams',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        this.paramType = paramType;
        return {
            searchs: [
                { id: hm.createId(), label: '创建日期', placeholder: '选择日期', type: 'daterange', bind: 'date', onChange: this.onChangeDateRange },
                { id: hm.createId(), label: '关键字', placeholder: '输入关键字', type: 'input', bind: 'title' },
            ],
            dataColumn: [
                { key: 'title', title: '参数项', minWidth: 120 },
                // { slot: 'classType', title: '所属分类', minWidth: 200 },
                { slot: 'type', title: '参数类型', minWidth: 120 },
                { slot: 'status', title: '是否显示', minWidth: 90 },
                { slot: 'ctime', title: '创建时间', minWidth: 200 },
                { slot: 'manager', title: '操作', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getClassTypeName(classList = {}) {
            return classList.title + '-' + (classList.children || {}).title;
        },
        onChangeDateRange(evt) {
            this.params.startDate = evt[0] || null;
            this.params.endDate = evt[1] || null;
        },
        //获取列表数据
        getList() {
            console.log('getList', this.params);
            this.showAjaxLoading();
            reqGoodsParameter(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_row = null) {
            let param = hm.isNotNullObject(_row) ? { ...(_row || {}) } : { create: 1 };
            this.goName('goodParamsAdd', param);
        },
        //删除
        onDelete(info = {}) {
            let { id, title } = info || {};
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();

                    reqGoodsDelParameter({ id })
                        .then(() => {
                            this.fadeAlert('删除成功');
                            this.getList();
                        })
                        .catch(res => {
                            this.fadeWarning(res.errmsg);
                        });
                }
            });
        },
    },
};
</script>
